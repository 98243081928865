<template>
  <v-container>
    <v-card class="pa-0">
      <v-card-title>
        <span class="text-capitalize">{{ vendor_details.name }} Resource List</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="pa-0"
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="headers"
        :search="search"
        :items="items.results"
        :server-items-length="items.totalResults"
        :options.sync="pagination"
        :loading="loading"
        item-key="name"
        class="elevation-1"
      >
        <template v-slot:item.file="{item}">
          <v-btn color="grey darken-2 px-2" small dark :href="item.file_info ? item.file_info.location : item.file_link" target="_blank">
            <v-icon class="pr-2">{{ item.file_type == "LINK" ? "mdi-link" : ( item.file_type == "VIDEO" ? "mdi-video" : (item.file_type == "IMAGE" ? "mdi-image-area" : "mdi-link" ) ) }}</v-icon> View
          </v-btn>
        </template>
        <template v-slot:item.action="{item}">
           <v-btn
            color="grey darken-2"
            icon
            dark
            @click="openEditDialog(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn color="grey darken-2" icon dark @click="confirmDelete(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-btn color="primary" fixed fab bottom right :to="'/vendor/add_resource/'+this.$route.params.vendor_id">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-btn small color="secondary" fixed fab bottom left to="/vendor">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <!-- Delete confirmation -->
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-text class="pt-5">
          Are you sure delete?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="red darken-4" text @click="deleteItem()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Video play -->
    <v-dialog v-model="preview_data" max-width="400" v-if="show_preview">
      <v-card>
        <v-card-text class="pt-2 rounded-lg px-0">
          <video v-if="preview_data.file_type=='VIDEO'" :src="preview_data.file" width="100%" controls></video>
          <v-img v-if="preview_data.file_type=='IMAGE'" :src="preview_data.file" contain max-height="300px"></v-img>
        </v-card-text>
         <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="show_preview = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" max-width="500px" persistent>
      <v-card v-if="selectedItem">
        <v-card-title class="headline">Edit Resource</v-card-title>

        <v-card-text class="pt-5 pb-3">
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="selectedItem.title"
                :error-messages="form_error.title"
                dense
                label="Title"
                outlined
                autofocus
                :hide-details="form_error.title == null"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                rows="3"
                v-model="selectedItem.description"
                :error-messages="form_error.description"
                dense
                label="Description"
                outlined
                :hide-details="form_error.description == null"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mt-2 pb-0">
            <v-col cols="9">
              <v-file-input
                v-model="editFile"
                :error-messages="form_error.file"
                accept="image/*, video/*,document/*,.pdf"
                placeholder="Select File"
                prepend-icon="mdi-file"
                outlined
                dense
                v-if="edit_custom_file_mode"
                persistent-hint
              ></v-file-input>
              <v-text-field
                v-model="editFileLink"
                :error-messages="form_error.link"
                dense
                label="Link"
                prepend-icon="mdi-link"
                outlined
                placeholder="https://"
                v-else
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="pa-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="edit_custom_file_mode = !edit_custom_file_mode"
                    v-bind="attrs"
                    v-on="on"
                    left
                    class="mt-3"
                  >
                    <v-icon v-if="!edit_custom_file_mode"
                      >mdi-file-upload-outline</v-icon
                    >
                    <v-icon v-else>mdi-link</v-icon>
                  </v-btn>
                </template>
                <span
                  >Switch to
                  {{ !edit_custom_file_mode ? "File upload" : "Link" }}</span
                >
              </v-tooltip>
            </v-col>
            <v-col cols="1">
              <v-btn
                :href="selectedItem.file_info ? selectedItem.file_info.location : selectedItem.file_link"
                color="primary px-0"
                dark
                target="_blank"
              >
                View
              </v-btn>
            </v-col>
          </v-row>
          <!-- <v-row class="pt-0 mt-0">
            <v-col cols="12">
              
            </v-col>
          </v-row> -->
        </v-card-text>

        <v-card-actions class="pb-5 pt-0">
          <v-spacer></v-spacer>
          <v-btn color="green" dark @click="updateItem" :loading="loading"> Update </v-btn>
          <v-btn color="primary" text @click="closeEditDialog"> close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";
import { parseParams, getAlphabets } from "@/plugins/helper";

export default {
  name: "ResourceList",
  data() {
    return {
      vendor_id : null,
      preview_data:null,
      show_preview:false,
      loading: false,
      dialog: false,
      resource_data:{},
      search: "",
      items: {
        current_page: 1,
        per_page: 10,
        data: [],
      },
      headers: [
        { text: "Title", value: "title" },
        { text: "Description", value: "description" },
        { text: "Link/File", value: "file",sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
      pagination: {
        itemsPerPage: 10,
        sortBy: ["created_at"],
        page: 1,
        filters: {
          item_active: true,
          alphabet: "",
        },
      },
      alphabets: getAlphabets(),
      vendor_details: {},
      form_error: {},
      editDialog: false,
      edit_custom_file_mode: true,
      selectedItem: null,
      editFile: null,
      editFileLink: null,
    };
  },
  computed: {
    pageData() {
      let paginationData = this.pagination;
      paginationData.search = this.search;
      paginationData.searchOption = "title";
      return paginationData;
    },
    getStyles() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return {
          maxWidth: "95%",
          margin: "0 auto",
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    search() {
      this.getItems();
    },
    pagination: {
      handler: function () {
        this.getItems();
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    showPreview(item) {
      this.preview_data = item;
      this.show_preview = true;
    },
    getItems() {
      let _self = this;
      this.loading = "secondary";
      let url = parseParams(this.pageData);
      this.$axios.get(`/admin/resource/list/${_self.$route.params.vendor_id}?${url}`)
        .then((response) => {
          _self.items = response.data.data;
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    confirmDelete(item){
      this.dialog = true;
      this.resource_data = item;
    },
    updateItem() {
      this.loading = true;

      let formData = new FormData();

      formData.append("title", this.selectedItem.title || "");
      formData.append("description", this.selectedItem.description || "");
      if (this.editFile) {
        formData.append("file", this.editFile);
      }
      formData.append("is_file", this.edit_custom_file_mode);
      formData.append("link", this.editFileLink || "");

      this.$axios
        .put(`/admin/resource/edit/${this.selectedItem.id}`, formData)
        .then((res) => {
          let data = res.data;
          if (data.status) {
            this.getItems();
            this.closeEditDialog();
            this.setAlert({
              show: true,
              variant: "success",
              message: data.message,
              dismissCountDown: 5000,
            });
          } else {
            let errors = data.error;
            for (let key in errors) {
              this.form_error[errors[key].field] = errors[key].message;
            }
          }
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    openEditDialog(resource) {
      this.selectedItem = { ...resource };
      this.editDialog = true;
    },
    closeEditDialog() {
      this.editDialog = false;
      this.selectedItem = null;
      this.editFile = null;
      this.editFileLink = null;
      this.edit_custom_file_mode = true;
    },
    deleteItem() {
      const index = this.items.results.indexOf(this.resource_data);
      let _self = this;
      _self.loading = "secondary";
      this.$axios.delete("/admin/resource/delete/"+this.resource_data.id)
      .then((res) => {
        if (res.data.status) {
          _self.items.results.splice(index, 1);
          _self.dialog = false
        }
        _self.loading = false;
      })
      .catch(function () {
        _self.loading = false;
      });
    },
    getVendorDetails() {
      this.$axios.get(`/admin/vendor/details/${this.$route.params.vendor_id}`)
      .then(response => {
        let data = response.data;

        if(data.status) {
          this.vendor_details = data.data;
        }
      }) 
    }
  },
  mounted(){
    this.getVendorDetails();
  }
};
</script>